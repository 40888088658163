import { Location } from "@angular/common"
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostBinding,
    HostListener,
    OnInit,
    ViewEncapsulation,
} from "@angular/core"
import {
    ActivatedRoute,
    ChildrenOutletContexts,
    NavigationEnd,
    Router,
    RoutesRecognized,
} from "@angular/router"
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap"
import { NotificationsService } from "angular2-notifications"

import { MessageService } from "./services/message.service"
import { PendoService } from "./services/pendo.service"
import { UserService } from "./services/user.service"
import { ModalService } from "./shared-components/modal/modal.service"
import { AuthModalComponent } from "./shared-components/modals/auth-modal/auth-modal.component"
import { NotificationModalComponent } from "./shared-components/modals/notification-modal/notification-modal.component"
import { redirectToError } from "./utils/error-handling"
import Utils from "./utils/utils"
import { AddOnViewService } from "./services/add-on-view.service"
import { StudentGateService } from "./services/student-gate.service"
import { PreviewService } from "./services/preview.service"
import { StudentSignInModalComponent } from "./shared-components/modals/student-sign-in-modal/student-sign-in-modal.component"
import { combineLatest, Observable } from "rxjs"
import { filter, map, pairwise } from "rxjs/operators"
import { animate, query, style, transition, trigger } from "@angular/animations"
import { routeAnimations, routeTransition } from "./utils/animations"

declare var window: any

const tagTypeMap = {
    error: "error",
    success: "success",
    warning: "warn",
    info: "info",
}

const addOnLoginRoute = "/add-on/log-in"

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None,
    host: { "[@routeTransition]": "true", style: "display: block;" },
    animations: [routeAnimations, routeTransition],
})
export class AppComponent implements OnInit, AfterViewInit {
    @HostBinding("class") hostBinding: string = "app-wrapper"
    @HostListener("window:beforeunload", ["$event"])
    clearLocalStorage() {
        const gradeGateLocalStorage: string = localStorage.getItem("grade-gate")
        if (!!gradeGateLocalStorage && gradeGateLocalStorage !== "null")
            sessionStorage.setItem("grade-gate", gradeGateLocalStorage)
        localStorage.removeItem("grade-gate")
    }

    isDisplayed: boolean = true
    isNavigationStopped: boolean = false

    isInPreviewMode$: Observable<boolean>
    isCollectionInPreviewMode$: Observable<boolean>
    isSubCollectionInPreviewMode$: Observable<boolean>
    isResourceInPreviewMode$: Observable<boolean>

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private modalService: ModalService,
        private userService: UserService,
        private notificationService: NotificationsService,
        private pendoService: PendoService,
        private tooltipConfig: NgbTooltipConfig,
        private location: Location,
        private addOnService: AddOnViewService,
        private messageService: MessageService,
        private studentGateService: StudentGateService,
        private cdr: ChangeDetectorRef,
        private previewService: PreviewService,
        private contexts: ChildrenOutletContexts
    ) {
        window.PBSLM.browser = {
            ie: Utils.isIEBrowser(),
            safari: Utils.isSafari(),
            edge: Utils.isEdgeBrowser(),
        }
        if (window.PBSLM.ERROR_CODE) {
            redirectToError(this.router, this.location, window.PBSLM.ERROR_CODE)
            window.PBSLM.ERROR_CODE = null
            return
        }

        this.configureTooltip()
        this.setStudentGateFromSessionStorageToLocalStorage()
        this.setPreviousUrlInLocalStorage()
    }

    getRouteAnimationData() {
        return this.contexts.getContext("primary")?.route?.snapshot?.data?.["animation"]
    }

    public ngOnInit() {
        this.isCollectionInPreviewMode$ = this.previewService.previewOnCollection$
        this.isSubCollectionInPreviewMode$ = this.previewService.previewOnSubCollection$
        this.isResourceInPreviewMode$ = this.previewService.previewOnResource$
        this.isInPreviewMode$ = combineLatest([
            this.previewService.previewOnResource$,
            this.previewService.previewOnCollection$,
            this.previewService.previewOnSubCollection$,
        ]).pipe(
            map(
                ([previewOnResource, previewOnCollection, previewOnSubCollection]: [
                    boolean,
                    boolean,
                    boolean
                ]) => {
                    return previewOnResource || previewOnCollection || previewOnSubCollection
                }
            )
        )
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // for add-on login route we don't display header and footer
                if (this.addOnService.isAddonView && this.router.url.startsWith(addOnLoginRoute)) {
                    this.isDisplayed = false
                }

                const url: URL = new URL(this.router.url, window.location.origin)
                const urlFragments: string[] = url.pathname.split("/")
                const studentQueryParam: boolean = !!url.searchParams.get("student")
                if (urlFragments[1] && urlFragments[1] === "") {
                    // Homepage case
                    this.userService.setStudentExperience(false)
                } else if (urlFragments[1] && urlFragments[1] === "student") {
                    // Student urls
                    this.userService.setStudentExperience(true)
                    //case for assignments and classes when paste the url without query param
                    if (urlFragments[2] && !studentQueryParam) {
                        this.router.navigate([], {
                            queryParams: { student: true },
                        })
                    }
                } else if (this.userService.isStudent() && !studentQueryParam) {
                    // student is logged in, should always have student experience and student=true
                    // but we need to keep existing params for error navigation for example
                    this.router.navigate([], {
                        queryParams: { student: true },
                        queryParamsHandling: "merge",
                        skipLocationChange: true,
                    })
                } else {
                    // student=true url case
                    this.userService.setStudentExperience(studentQueryParam)
                }
            }
        })

        this.userService.runOnceForQueryParam(this.activeRoute, "login", () => {
            if (!this.userService.isLoggedIn()) {
                if (this.userService.hasStudentExperience()) {
                    this.modalService.open(StudentSignInModalComponent)
                } else {
                    this.modalService.open(AuthModalComponent)
                }
            }
        })

        this.pendoService.initialize()
        this.scrollToTopBeforeUnload()
    }
    private setStudentGateFromSessionStorageToLocalStorage(): void {
        const gradeGateSessionStorage: string = sessionStorage.getItem("grade-gate")
        this.setStudentGateInLocalStorage(gradeGateSessionStorage)
    }

    private setStudentGateInLocalStorage(gradeGateSessionStorage: string): void {
        if (gradeGateSessionStorage && gradeGateSessionStorage !== "null") {
            this.studentGateService.selectedGradeValue = gradeGateSessionStorage
            localStorage.setItem("grade-gate", gradeGateSessionStorage)
        }
    }

    private scrollToTopBeforeUnload() {
        window.onbeforeunload = () => {
            document.querySelector("html").style.scrollBehavior = "auto"
            document.documentElement.scrollTop = 0
        }
    }

    public onActivate() {
        setTimeout(() => {
            window.scroll(0, 0)
        })
        this.preventEmbedCycles()
        this.showServerSetMessages()
    }

    ngAfterViewInit() {
        this.studentGateService.isNavigationStopped.subscribe((value) => {
            if (!this.addOnService.isAddonView) {
                this.isNavigationStopped = value
                this.cdr.detectChanges()
            }
        })
    }

    private preventEmbedCycles() {
        try {
            if (window.top !== window.self && window.top.location.host === window.location.host) {
                /* The current application is embeded in an iframe, aka "double header bug", most likely due to an
                 * old site navigation issue. We want to load the most recent viewed page in the top browser window.
                 * We also, only want to do this the current application is embedded on the same domain.
                 */
                window.top.location.href = window.self.location.href
            }
        } catch (err) {
            console.info("Cannot access window top from iframe. Skipping preventEmbedCycles")
        }
    }

    private showServerSetMessages() {
        this.messageService.getMessages().subscribe((response) => {
            if (!!response?.results) {
                response.results.forEach((item) => {
                    this.showMessage(item)
                })
            } else {
                console.error('Invalid response or missing "results" property:', response)
                this.showMessage("Something went wrong, please try again.")
            }
        })
    }

    private showMessage(messageItem) {
        if (messageItem.show_in_modal) {
            this.displayMessageInModal(messageItem.message)
        } else {
            const type: string = this.determineNotificationType(messageItem.tags)
            const params = { timeOut: 10000, showProgressBar: false }
            this.showNotification(messageItem.message, type, params)
        }
    }

    private displayMessageInModal(message: string): void {
        this.modalService.open(NotificationModalComponent, {
            data: { message },
        })
    }

    private determineNotificationType(tags: string[]): string {
        return (
            tags
                .filter((tag: string) => tagTypeMap[tag])
                .map((tag: string) => tagTypeMap[tag])[0] || "info"
        )
    }

    private showNotification(message: string, type: any, params: any): void {
        this.notificationService.html(message, type, params)
    }

    private configureTooltip() {
        this.tooltipConfig.triggers = "hover"
        this.tooltipConfig.openDelay = 300
        this.tooltipConfig.container = "body"
        this.tooltipConfig.placement = "top"
    }

    public onExitPreview(): void {
        if (this.userService.isLoggedIn() && this.userService.hasAdminDisplay()) {
            window.location.href = this.buildRedirectToCMS()
        } else {
            this.router.navigate(["."], { queryParamsHandling: "merge" })
        }
    }

    private buildRedirectToCMS(): string {
        const baseURL = window.location.origin + "/" + "admin/cms/"
        if (this.previewService.previewOnCollection)
            return baseURL + `collection/${this.previewService.collectionId}/change`
        if (this.previewService.previewOnResource)
            return baseURL + `resource/${this.previewService.resource.id}/change`
        if (this.previewService.previewOnSubCollection)
            return (
                baseURL +
                `${this.previewService.subCollectionType.replace(/_/g, "")}subcollection/${
                    this.previewService.subCollectionId
                }/change`
            )
    }

    public setPreviousUrlInLocalStorage(): void {
        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof RoutesRecognized),
                pairwise()
            )
            .subscribe((events: RoutesRecognized[]) => {
                localStorage.setItem("previousURL", events[0].urlAfterRedirects)
            })
    }
}
